import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <h2 className="text-2xl font-bold inline-block my-8 p-3">
          Nope, this page doesn't exist
          <span className="ml-2" aria-label="thinking face" role="img">
            🤔
          </span>
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
